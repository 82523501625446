import BlackHorizontal from '~/assets/logos/dc/Black_Horizontal.png';
import BlackStacked from '~/assets/logos/dc/Black_Stacked.png';
import BlueWhiteHorizontal from '~/assets/logos/dc/Blue-White_Horizontal.png';
import BlueWhiteStacked from '~/assets/logos/dc/Blue-White_Stacked.png';
import FullColorHorizontal from '~/assets/logos/dc/Color_Horizontal.png';
import FullColorStacked from '~/assets/logos/dc/Color_Stacked.png';
import IconBlack from '~/assets/logos/dc/Icon_Black.png';
import IconWhite from '~/assets/logos/dc/Icon_White.png';
import IconFullColor from '~/assets/logos/dc/Icon_Color.png';
import WhiteHorizontal from '~/assets/logos/dc/White_Horizontal.png';
import WhiteStacked from '~/assets/logos/dc/White_Stacked.png';
import { Image } from '.';

export enum DCLogoTypes {
    BlackHorizontal,
    BlackStacked,
    BlueWhiteHorizontal,
    BlueWhiteStacked,
    FullColorHorizontal,
    FullColorStacked,
    IconBlack,
    IconWhite,
    IconFullColor,
    WhiteHorizontal,
    WhiteStacked,
}

interface LogoProps {
  type: DCLogoTypes;
  size: number;
  className?: string;
}

export const Logo = (props: LogoProps) => {
    const logoSrc =
      props.type === DCLogoTypes.BlackHorizontal
        ? BlackHorizontal
        : props.type === DCLogoTypes.BlackStacked
        ? BlackStacked
        : props.type === DCLogoTypes.BlueWhiteHorizontal
        ? BlueWhiteHorizontal
        : props.type === DCLogoTypes.BlueWhiteStacked
        ? BlueWhiteStacked
        : props.type === DCLogoTypes.FullColorHorizontal
        ? FullColorHorizontal
        : props.type === DCLogoTypes.FullColorStacked
        ? FullColorStacked
        : props.type === DCLogoTypes.IconBlack
        ? IconBlack
        : props.type === DCLogoTypes.IconWhite
        ? IconWhite
        : props.type === DCLogoTypes.IconFullColor
        ? IconFullColor
        : props.type === DCLogoTypes.WhiteHorizontal
        ? WhiteHorizontal
        : props.type === DCLogoTypes.WhiteStacked
        ? WhiteStacked
        : FullColorHorizontal;

  return (
    <Image
      src={logoSrc}
      alt={'Logo ' + DCLogoTypes[props.type]}
      height="auto"
      width={props.size}
      className={props.className}
    />
  )
}